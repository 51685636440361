import axios from "../plugins/axios";

const OrderService = {
    async OrdersByUserKey(id){
        try{
            const {data} = await axios.get(`${process.env.VUE_APP_API_URL}order-with-telegram/${id}`);
            return data.filter(x => x.order_status === 'new' || x.order_status === 'is_processing');
        }catch (e) {
            return e
        }
    },
    async ChangeOrderStatus(payload){
        try{
            const {data} = await axios.put(`${process.env.VUE_APP_API_URL}change-status-order`,payload);
            return data;
        }catch (e) {
            return e;
        }
    }
}
export default OrderService