<template>
  <div style="display: flex; justify-content: center">
    <div>
      <v-icon></v-icon>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        padding: 15px;
        max-width:600px;
      "
    >
      <v-btn icon @click="getToken()">
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <div
        style="display: flex; flex-direction: column"
        class="item"
        v-for="(item, index) in orderList"
        :key="item.id"
      >
        <v-btn
          small
          icon
          @click="deleteOrder(item)"
          style="position: absolute; top: 10px; right: 10px"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div>
          {{ dateObj(item.created_at) }}
        </div>
        <div style="display: flex; position: relative">
          <div class="item__image">
            <img
              :src="require('../assets/none.png')"
              style="max-width: 100%; height: auto"
            />
          </div>
          <div class="item__content">
            <div class="item__content__text" style="margin-bottom: 15px">
              Order #{{ index + 1 }}
              <br />
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div v-for="(group, index) in item.order_data" :key="index">
                  <div v-for="product in group" :key="product.id">
                    <div class="item__content__text__title">
                      {{ product.label }} {{ product.price }}  {{item.currency}}
                    </div>
                  </div>
                </div>
                <div
                  class="item__content__counter__price"
                  style="text-align: right"
                >
                  Total: {{ itemPrice(item) }} {{item.currency}}
                </div>
              </div>
            </div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div>Status: {{ item.order_status }}</div>
              <v-btn
                color="primary"
                outlined
                small
                style="max-width: 100px"
                @click="saveChanges(item)"
                v-if="item.order_status === 'new'"
                >Confirm</v-btn
              >
              <v-btn
                color="primary"
                outlined
                small
                style="max-width: 100px"
                @click="saveChanges(item)"
                v-if="item.order_status === 'is_processing'"
                >Finish</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderService from "../services/order.service";
import TelegramUserService from "../services/telegramUser.service";
import moment from "moment";

export default {
  name: "ViewByUserToken",
  data() {
    return {
      user: null,
      app: null,
      orderList: [],
      messaging: null,
    };
  },

  methods: {
    async deleteOrder(item) {
        item.order_status = "deleted";
        const payload = {
            order_id: item.id,
            telegram_key: this.$route.params.token,
            order_status: item.order_status,
        };
      await OrderService.ChangeOrderStatus(payload);
      const candidate = this.orderList.find((x) => x.id === item.id);
      if (candidate) {
        const candidateIndex = this.orderList.indexOf(candidate);
        this.orderList.splice(candidateIndex, 1);
      }
    },
    backgroundMessage(message) {
      // console.log(message);
    },
    messageHandler(message) {
      // console.log(message);
    },
    async getToken() {

      // if ("Notification" in window) {
      //   await window.Notification.requestPermission();
      //   if (window.Notification.permission === "granted") {
      //     this.messaging = getMessaging(this.app);
      //     const token =
      //       await this.messaging.firebaseDependencies.installations.getToken();
      //     this.messaging.onBackgroundMessageHandler = this.backgroundMessage;
      //     this.messaging.onMessageHandler = this.messageHandler;
      //     if (token) {
      //       this.user.firebase_token = token;
      //       const user = await TelegramUserService.updateUserToken(this.user);
      //     }
      //   }
      // } else {
      //   alert("You browser not supporting notifications");
      // }
    },
    dateObj(date) {
      return moment(date).fromNow();
    },
    total() {
      let total = 0;
      this.orderList.forEach((item) => {
        let totalPrice = 0;
        for (let i = 0; i < item.order_data.length; i++) {
          for (let index = 0; index < item.order_data[i].length; index++) {
            const data = item.order_data[i][index];
            if (data.radio === "checkbox" && data.text) {
              totalPrice += +data.price;
            }
            if (data.radio === "radio") {
              totalPrice += data.price;
            }
            if (data.radio === "number") {
              totalPrice += data.price * data.count;
            }
            if (data.radio === "text") {
              totalPrice += +data.price;
            }
          }
        }
        total += totalPrice;
      });
      return total;
    },
    async saveChanges(item) {
      if (item.order_status === "is_processing") {
        item.order_status = "completed";
        const payload = {
          order_id: item.id,
          telegram_key: this.$route.params.token,
          order_status: item.order_status,
        };
        await OrderService.ChangeOrderStatus(payload);
        let candidate = this.orderList.find((x) => x.id === item.id);
        this.orderList.splice(this.orderList.indexOf(candidate), 1);
      }
      if (item.order_status === "new") {
        item.order_status = "is_processing";
        const payload = {
          order_id: item.id,
          telegram_key: this.$route.params.token,
          order_status: item.order_status,
        };
        const { data } = await OrderService.ChangeOrderStatus(payload);
        let candidate = this.orderList.find((x) => x.id === item.id);
        this.orderList.splice(this.orderList.indexOf(candidate), 1, data);
      }
    },
    itemPrice(item) {
      let totalPrice = 0;
      for (let i = 0; i < item.order_data.length; i++) {
        for (let index = 0; index < item.order_data[i].length; index++) {
          const data = item.order_data[i][index];
          if (data.radio === "checkbox" && data.text) {
            totalPrice += +data.price;
          }
          if (data.radio === "radio") {
            totalPrice += data.price;
          }
          if (data.radio === "number") {
            totalPrice += data.price * data.count;
          }
          if (data.radio === "text") {
            totalPrice += +data.price;
          }
        }
      }
      return totalPrice;
    },
  },
  async mounted() {
    this.user = await TelegramUserService.getUserInfoByKey(
      this.$route.params.token
    );
    this.orderList = await OrderService.OrdersByUserKey(
      this.$route.params.token
    );
    await setInterval(async () => {
      this.orderList = await OrderService.OrdersByUserKey(
        this.$route.params.token
      );
    }, 30000);
  },
};
</script>

<style scoped lang="scss">
.item {
  background: rgb(250, 250, 250);
  border-radius: 15px;
  margin-bottom: 15px;
  width: 100%;
  position: relative;
  min-height: 170px;
  padding: 15px;
  display: flex;

  &__image {
    border-radius: 15px;
    height: 100%;
    flex: 1;
    display: flex;
    justify-items: center;
    align-items: center;
    margin-right: 5px;
    img {
      border-radius: 15px;
    }
  }
  &__content {
    flex: 3;
    border-radius: 15px;
    height: 100%;
    padding: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__text {
      &__description {
        color: grey;
        font-size: 14px;
      }
    }

    &__delete {
      position: absolute;
      top: 0px;
      right: 0px;
      font-size: 15px;
    }

    &__counter {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
